<template>
  <div class="content">
    <div class="bx bx-padding">
      <div class="middle-title">
        <img src="@/assets/Group 32@2x.png" alt="" />
        <div class="title">以新的方式購買NFT</div>
      </div>
      <div class="middle">
        <div class="item" style="padding-left: .51rem;">
          <img src="@/assets/Group@2xxx.png" alt="" />
          <div class="text">
            無手續費，快速安全<br />
多種支付方式，方便快捷
          </div>
        </div>
        <div class="item" style="padding-left: 2.29rem; padding-right: .72rem;">
          <img style="width: 1.14rem; height: 2.77rem; margin-right: 2.54rem;" src="@/assets/Group 9@2x.png" alt="" />
          <div class="text">
            全亞洲第一部NFT自動販賣機<br />
連接專業的NFT項目和買家<br />
讓藝術家和創作者可以向所有人出售數位藝術<br />
同時幫助想成為收藏家的人以更方便的方式支持<br />他們的所喜愛的創作者
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  .content {
  .bx {
    .middle-title {
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        width: 3.49rem;
        height: 1.85rem;
        margin-bottom: .26rem;
      }
      .title {
        font-size: .7rem;
        font-weight: 600;
        color: #4f4f4f;
        margin-bottom: .94rem;
      }
    }
    .middle {
      height: 8.87rem;
      background: #ffffff;
      box-shadow: 0 .12rem .43rem 0px rgba(0, 0, 0, 0.3);
      border-radius: .27rem;
      box-sizing: border-box;
      padding: 1.3rem 0 1.76rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item {
        display: flex;
        align-items: center;
        img {
          width: 4.90rem;
          height: 1.70rem;
          margin-right: .56rem;
        }
        .text {
          font-size: .3rem;
          font-weight: 400;
          color: #4f4f4f;
          line-height: .6rem;
        }
      }
    }
  }
}
</style>