<template>
<div class="market">
    <div class="market-bx" style="padding-bottom: 0;">
  <div class="market_details_content">
    <div class="subject">
      <div class="title">
        <img class="title_img" :src="List.nftPath" alt="" />
        <div class="title_content">
          <div class="title_name">{{ List.code }}</div>
          <div class="title_logo" style="margin-top: 10px;">
            <div class="title_solana">
              <img
              v-if="List.nftId"
                :src="
                  List.chain === 'Solana'
                    ? '/img/SOLANA.png'
                    : List.chain === 'Ethereum'
                    ? '/img/marketgroup9copy.png'
                    : '/img/marketgroup10copy.png'
                "
                alt=""
              />
              <span>{{ List.chain }}</span>
            </div>
            <img style="width: 218px; 70px;" :src="introduce.logoZhTw" alt="">
          </div>
          <div class="introduce">
            <div class="introduce_top">
              <div
                v-for="item in attr"
                :key="item.index"
                class="introduce_text"
              >
                <p>{{ item.head }}</p>
                <p style="color: #fff;">{{ item.body }}</p>
              </div>
            </div>
          </div>
          <div class="buy">
            <div class="buy_left" v-if="List.nftId">
              <p>{{ priceDispose(List.price) + '&nbsp;' + List.currency }}</p>
              <img src="/img/Group@2xx22x.png" alt="" @click="PayListBtn" />
            </div>
            <div @click="updateLike(List)" class="buy_right">
              <div
                style="
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img
                 v-if="List.nftId"
                  :src="
                    LikeList.some((v) => v.nftId === List.nftId)
                      ? '/img/like@2x.png'
                      : '/img/market_zan.png'
                  "
                  alt=""
                />
                <span>{{ List.likes }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        {{ introduce.description }}
      </div>
      <div class="series" v-if="swiperList.length !== 0">
        <p class="series_title">Same Series</p>
        <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(item, index) in swiperList" :key="index">
            <div class="swiperContent">
              <div class="series_img">
                <div class="right_img" v-for="value in item" :key="value.nftId">
                  <div class="top" @click="detailsBtn(value)">
                    <img class="img" :src="value.nftPath" alt="" />
                    <img
                      @click.stop="magnifyBtn(value.nftPath)"
                      class="search"
                      src="/img/market_zoom.png"
                      alt=""
                    />
                    <div class="Solana newSolana">
                      <img
                        :src="
                          value.chain === 'Solana'
                            ? '/img/SOLANA.png'
                            : value.chain === 'Ethereum'
                            ? '/img/marketgroup9copy.png'
                            : '/img/marketgroup10copy.png'
                        "
                        alt=""
                      />
                      <span>{{ value.chain }}</span>
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="name_price">
                      <p class="name">{{ value.code }}</p>
                      <p class="price">
                        {{ priceDispose(value.price) + '&nbsp;' + value.currency }}
                      </p>
                    </div>
                    <div class="zan" @click="updateLike(value)">
                      <img
                        :src="
                          LikeList.some((v) => v.nftId === value.nftId)
                            ? '/img/like@2x.png'
                            : '/img/market_zan.png'
                        "
                        alt=""
                      />
                      <span>{{ value.likes }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev">
          </div>
          <div class="swiper-button-next" slot="button-next">
          </div>
        </swiper>
      </div>
    </div>
    <div class="ending">
      <p>
        一、Wefiv藝術版權作品權益須知：著作權人同意，在交易完成後，您將獲得該數字藝術品除人身權利外的其他著作權（包括復製權、發行權、出租權、展覽權、表演權、放映權、廣播權、信息網絡傳播權、攝製權、改編權、翻譯權、匯編權等）。
        特別提示：如您有意對該數字藝術品進行商用許可、授權等，建議您與著作權人訂立書面的「著作權轉讓協議」並進行著作權轉讓備案登記。
      </p>
      <p style="margin-bottom: 25px;">
        二、Wefiv藝術衍生品作品權益須知：發售方同意並承諾，在交易完成後，您即享有對該數字藝術品進行占有、使用、處分的權利。
        特別提示：您對該數字藝術品的占有、使用、處分不得侵犯作者著作權，即您只能以合理的或著作權人可預見的方式展示、展覽、匯編該數字資產中載有的作品，亦可向其他人轉讓該數字藝術品。如您需要對該數字藝術品做出復製、出租、改編、放映、廣播或進行網絡傳播等涉及作品著作權授權的行為，您應獲得著作權人的相應授權，並訂立相關的協議。
      </p>
      <p>
        為更好服務Wefiv藝術平臺的各位用戶，Wefiv藝術特此公告數字藏品的二次交易細則。鑒於不同屬性的數字藝術的二次交易細則各有不同，請各位用戶務必審慎閱讀並同意相關規則後進行相應操作，以免造成不必要損失。
      </p>
      <p>
        1、依據我國法律要求，特定條件下的數字藏品的二次交易不支持任何形式的變相炒作，我們堅決反對數字藏品炒作、場外交易、欺詐，或以任何其他非法、侵權方式使用的違法違規行為，請大家註意甄別上述涉嫌網絡欺詐的行為及其相關風險。
      </p>
      <p>
        2、數字藏品為虛擬數字商品，購買前請完成實名認證，未滿18周歲人群禁止購買。
      </p>
      <p>3、本商品一經出售不支持七天無理由退換貨。</p>
    </div>
    <el-dialog width="35%" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
    </div>
  </div>
</template>

<script>
Array.prototype.indexOf = function(val) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == val) return i
  }
  return -1
}
Array.prototype.remove = function(val) {
  var index = this.indexOf(val)
  if (index > -1) {
    this.splice(index, 1)
  }
}
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import {
  marketDetails,
  updateLike,
  getSysLikeList,
  marketList,
  createNftCommodityOrder,
  getCategoryData,
} from '../../api/api'
import { mapState } from 'vuex'
import { newAttr, priceDispose } from '../../common/common'
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      priceDispose,
      dialogVisible: false,
      dialogImageUrl: '',
      List: {},
      introduce: [], // 商品描述信息
      id: this.$route.query.nftCommodityId,
      attr: [],
      swiperList: [],
      LikeList: [],
      // [ { head: 'black', body: 'test' },{ head: 'black2', body: 'test2' },{ head: 'black3', body: 'test3' } ],
      swiperOption: {
        loop: false,
        autoplay: {
          delay: 3500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        speed: 800,
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {
    ...mapState(['userInfo']),
    swiper() {
      return this.$refs.mySwiper.swiper
    },
  },

  watch: {
    $route(to, from) {
      if(to.query.nftCommodityId !== from.query.nftCommodityId) {
        this.marketDetails(to.query.nftCommodityId)
      }
    },
  },
  created() {
    if(!this.List.code) {
      this.marketDetails().then(() => {
        this.marketList()
        this.getCategoryData()
      })
    }
  },
  methods: {
    async getCategoryData() {
      const res = await getCategoryData(this.List.category)
      this.introduce = res.data[0]
    },
    // 改造轮播图数据
    newSwiperList(List) {
      let result = []
      for (let i = 0; i < List.length; i += 3) {
        result.push(List.slice(i, i + 3))
      }
      return result
    },
    async marketList() {
      const res = await marketList({
        chain: [this.List.chain],
        status: 'enabled',
      })
      res.data.result.forEach((item) => {
        if (item.nftId === this.List.nftId) {
          res.data.result.remove(item)
        }
      })
      this.swiperList = this.newSwiperList(res.data.result)
    },
    async getSysLikeList(res) {
      // const res = await getSysLikeList()
      this.LikeList = res.data
    },
    magnifyBtn(url) {
      this.dialogVisible = true
      this.dialogImageUrl = url
    },
    detailsBtn(obj) {
      this.id = obj.nftCommodityId
      this.$router.push(
        `/market/marketDetails?nftCommodityId=${obj.nftCommodityId}`
      )
      this.marketList()
      // this.marketDetails()
      window, scrollTo(0, 0)
    },
    async updateLike(obj) {
      let isLike = null
      let LikeList = this.LikeList
      if (LikeList.length === 0) {
        isLike = '1'
      } else {
        var a = LikeList.some((item) => {
          return item.nftId === obj.nftId
        })
      }
      if (a) {
        isLike = '0'
      } else {
        isLike = '1'
      }
      const res = await updateLike({
        mobile: this.userInfo.name + this.userInfo.mobile,
        nftId: obj.nftId,
        isLike,
      })
      this.marketDetails()
      this.marketList()
      this.$message.success(res.message)
    },
    async marketDetails(data) {
      const marketDetails2 =  marketDetails(data ? data : this.id)
      const getSysLikeList2 =  getSysLikeList()
      const res = await marketDetails2
      const res2 = await getSysLikeList2
      this.List = res.data
      this.getSysLikeList(res2)
      this.attr = newAttr(this.List.attr)
    },
    async PayListBtn() {
      if (this.List.status !== 'enabled') return this.$message.warning('該訂單不是可售狀態')
      const res = await createNftCommodityOrder({
        nftId: parseInt(this.List.nftId),
      })
      this.$router.push(
        `/market/marketPay?nftId=${this.List.nftId}&nftCommodityId=${this.id}&orderNo=${res.data.orderNo}`
      )
    },
  },
}
</script>

<style></style>
